exports.components = {
  "component---src-pages-status-js": () => import("./../../../src/pages/status.js" /* webpackChunkName: "component---src-pages-status-js" */),
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-build-your-own-js": () => import("./../../../src/templates/build-your-own.js" /* webpackChunkName: "component---src-templates-build-your-own-js" */),
  "component---src-templates-custom-ls-editor-js": () => import("./../../../src/templates/custom-ls-editor.js" /* webpackChunkName: "component---src-templates-custom-ls-editor-js" */),
  "component---src-templates-favourites-js": () => import("./../../../src/templates/favourites.js" /* webpackChunkName: "component---src-templates-favourites-js" */),
  "component---src-templates-learning-scenario-js": () => import("./../../../src/templates/learning-scenario.js" /* webpackChunkName: "component---src-templates-learning-scenario-js" */),
  "component---src-templates-learning-unit-js": () => import("./../../../src/templates/learning-unit.js" /* webpackChunkName: "component---src-templates-learning-unit-js" */),
  "component---src-templates-open-schooling-materials-js": () => import("./../../../src/templates/open-schooling-materials.js" /* webpackChunkName: "component---src-templates-open-schooling-materials-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-share-js": () => import("./../../../src/templates/share.js" /* webpackChunkName: "component---src-templates-share-js" */)
}

