import React, { createContext, useEffect, useReducer, useState } from "react"
import { reducer, initializer } from "./records-reducer"

export const MyContext = createContext("") //empty arg is needed somehow!

export const MyProvider = ({ children }) => {

  // thoughtfully decided not to use combine reducer function, we just have several
  const [lsState, lsDispatch] = useReducer(reducer, [], initializer("ls"))
  const [favouritesState, favouritesDispatch] = useReducer(reducer, [], initializer("favourites"))
  const [importsState, importsDispatch] = useReducer(reducer, [], initializer("imports"))
  //
  const [dialogState, dialogDispatch] = useState(null)

  useEffect(() => {
    localStorage.setItem("ls", JSON.stringify(lsState))
  }, [lsState])

  useEffect(() => {
    localStorage.setItem("favourites", JSON.stringify(favouritesState))
  }, [favouritesState])

  useEffect(() => {
    localStorage.setItem("imports", JSON.stringify(importsState))
  }, [importsState])

  return (
    <MyContext.Provider
      value={{
        state: {
          ls: lsState,
          favourites: favouritesState,
          dialog: dialogState,
          imports: importsState
        },
        dispatch: {
          ls: lsDispatch,
          favourites: favouritesDispatch,
          dialog: dialogDispatch,
          imports: importsDispatch
        }
      }}
    >
      {children}
    </MyContext.Provider>
  )
}
