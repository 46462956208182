/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react"
import { MyProvider } from "./src/context/my-context"

export const onClientEntry = async () => {
  // add IntersectionObserver support for older browsers
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }

  // add css custom property support to ie11
  if (window.MSInputMethodContext && document.documentMode) {
    await import(`ie11-custom-properties`);
  }
}

export const wrapRootElement = ({ element }) => (
  <MyProvider>{element}</MyProvider>
)
