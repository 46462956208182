const initialState = []

export const initializer = (store) => (initialValue = initialState) => {
  return JSON.parse(localStorage.getItem(store)) || initialValue
}

export const reducer = (state, action) => {
  switch (action.t) {
    case "UPSERT": { // Object: { id: 1 , prop2: "bb", prop3: "cc" }
        let item = {
          ...state.filter((record) => record.id === action.p.id)[0],
          ...action.p
        }
        return [
          ...state.filter((record) => record.id !== action.p.id),
          item
        ]
      }
    case "REMOVE": // Number : id
      return state.filter((record) => record.id !== action.p);
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
}

export const upsert = function(dispatch, payload) {
  dispatch({
    t: "UPSERT",
    p: payload
  })
}

export const remove = function(dispatch, payload) {
  dispatch({
    t: "REMOVE",
    p: payload
  })
}

export const clear = function(dispatch, payload) {
  dispatch({
    t: "CLEAR",
    p: null
  })
}
